export const decryptVal = (string) => {
    try {
        string = atob(string);
        if (!isNaN(string)) {
            return string / 2;
        }
        return string;
    } catch (err) {
        return 0;
    }
};

export const encryptVal = (string) => {
    try {
        if (!isNaN(string)) {
            string = parseInt(string) + parseInt(string);
        }
        string = btoa(string);
        return string.replace(/=+$/, "");
    } catch (err) {
        return 0;
    }
};

export function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
export async function fileExists(url) {
  let status = false;
  return await fetch(url, { method: "HEAD" })
    .then((response) => {
      status = typeof response.ok !== "undefined" ? true : false;
      return status;
    })
    .catch((error) => {
      return false;
    });
}

export function convertToTitleCase(str) {
  return str
      .split('_') // Step 1: Split by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Step 2: Capitalize each word
      .join(' '); // Step 3: Join words with spaces
}
